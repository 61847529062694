<template>
  <v-container
    fluid
    class="text-justify"
  >
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">
          Erklärung zur Barrierefreiheit
        </h2>

        <p>
          Als öffentliche Stelle im Sinne der Richtlinie (EU) 2016/2102 sind wir bemüht, unsere Websites und mobilen
          Anwendungen im Einklang mit den Bestimmungen des Behindertengleichstellungsgesetzes (BGG NRW) sowie der
          Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW) zur Umsetzung der Richtlinie (EU) 2016/2102
          barrierefrei zugänglich zu machen.</p>

        <h3 class="text-subtitle-1 font-weight-bold mb-2">Stand der Vereinbarkeit mit den Anforderungen</h3>
        <p>
          Die Anforderungen der Barrierefreiheit ergeben sich aus Paragraphen 3 Absätze 1 bis 4 und Paragraph 4 der
          Barrierefreien-Informationstechnik-Verordnung NRW (BITV NRW), die auf der Grundlage von § 10e
          Behindertengleichstellungsgesetz (BGG NRW) erlassen wurde.</p>

        <p>
          Die Überprüfung der Einhaltung der Anforderungen beruht auf einer am {{ selfTest | date }} durchgeführten Selbstbewertung.
        </p>

        <p>
          Die Webanwendung VOKAR NRW steht verschiedenen Nutzergruppen zur Verfügung, welche unterschiedliche Funktionalitäten und Inhalte sehen und nutzen können.
          Daher hängt der Grad der Barrierefreiheit von der Nutzergruppe ab.

          Aufgrund der Überprüfung ist die Website je nach Nutzergruppe mit den zuvor genannten Anforderungen wegen der folgenden
          Unvereinbarkeiten teilweise vereinbar.</p>
        <p>
          Die nachstehend aufgeführten Prüfschritte sind nicht erfüllt und damit die Inhalte nicht barrierefrei.
        </p>
        <p>
          Nicht erfüllt:
        </p>
        <ul class="mb-4">
          <li>
            Tastaturbedienbarkeit und Tastaturfokus
            <ul>
              <li>Kontrollkästchen in Tabellen sind nicht erreichbar</li>
            </ul>
          </li>
          <li>
            Formulare:
             <ul>
               <li>Fortschrittsanzeige ist nicht beschriftet</li>
               <li>Kontrollkästchen in Tabellen sind nicht beschriftet</li>
             </ul>
          </li>
        </ul>
        <p>
          Alle aufgeführten Probleme wurden bzw. werden noch behoben. Ein Nachtest erfolgt zum nächst möglichen Zeitpunkt.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">
          Datum der Erstellung bzw. der letzten Aktualisierung der Erklärung
        </h3>
        <p>
          Diese Erklärung wurde am {{ created | date }} erstellt und zuletzt am {{ updated | date }} aktualisiert.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Nicht barrierefreie Inhalte</h3>

        <p>
          .... Wir bemühen uns um eine schnellstmögliche Behebung der noch bestehenden Barrieren.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Barrieren melden: Kontakt zu den Feedback Ansprechpartnern</h3>
        <p>
          Sie möchten uns bestehende Barrieren mitteilen oder Informationen zur Umsetzung der Barrierefreiheit erfragen?
          Für Ihr Feedback sowie alle weiteren Informationen sprechen Sie unsere verantwortlichen Kontaktpersonen
          <a :href="reportingForm">vokar-gs(at)lanuk.nrw.de</a> an.
        </p>
        <h3 class="text-subtitle-1 font-weight-bold mb-2">Schlichtungsverfahren</h3>

        <p>
          Wenn auch nach Ihrem Feedback an den oben genannten Kontakt keine zufriedenstellende Lösung gefunden wurde, können
          Sie sich an die Schlichtungsstelle nach § 10d Behindertengleichstellungsgesetz NRW (BGG NRW) wenden. Die
          Schlichtungsstelle BGG NRW hat die Aufgabe, bei Konflikten zum Thema Barrierefreiheit zwischen Menschen mit
          Behinderungen und öffentlichen Stellen des Bundes eine außergerichtliche Streitbeilegung zu unterstützen. Das
          Schlichtungsverfahren ist kostenlos. Es muss kein Rechtsbeistand eingeschaltet werden. Weitere Informationen zum
          Schlichtungsverfahren und den Möglichkeiten der Antragstellung erhalten Sie unter:
          <a href="https://www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik" target="_blank"
             rel="noopener noreferrer">www.mags.nrw/ombudsstelle-barrierefreie-informationstechnik</a>
        </p>
        <p>
          Direkt kontaktieren können Sie die Schlichtungsstelle BGG NRW unter: <a
          href="mailto:ombudsstelle-barrierefreie-it@mags.nrw.de">ombudsstelle-barrierefreie-it(at)mags.nrw.de</a>
        </p>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate } from '../common/lang'

export default {
  filters: {
    date (value) {
      return formatDate(value)
    }
  },
  data: () => ({
    selfTest: new Date('2022-02-28'),
    created: new Date('2022-02-28'),
    updated: new Date('2022-02-28'),
    reportingForm: 'mailto:vokar-gs@lanuk.nrw.de'
  })
}
</script>
